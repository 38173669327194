<template>


  <div class="main-wrap container is-fluid" :class="{ 'is-hasbar': hasRightBar }">
    <div class="columns">

      <div class="main-wrap__content column mt-5 py-5"  :class="{ 'is-two-thirds-desktop is-three-quarters-widescreen ': hasRightBar }">
        <div v-if="pageTitle !=='' " class="columns mb-5">
          <div class="column">
            <h2 class="title has-text-weight-semibold" :class="customTitleClass" >{{ pageTitle }}</h2>
            <slot name="subtitle"></slot>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-main" >
            <slot></slot>
          </div>
        </div>

      </div>
      <div v-if="hasRightBar" class=" main-wrap__right column is-rightbar pt-6">
        <slot name="rightbar"></slot>
      </div>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue'

export default {
  name: 'PageLayout',
  props: {
    pageTitle: {
      type: String,
      default: ''
    },

    hasRightBar: {
      type: Boolean,
      default: true
    },
    isFluid: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    customTitleClass: {
      type: String,
      default: 'is-3'
    }
  },

  setup (props) {
    const customClass = ref(props.customClass)
    const isFluid =  ref(props.isFluid)
    const _isFluidClass = isFluid.value ?  ' is-fluid ' : ''

    const renderClass = customClass.value + _isFluidClass

    return {
      renderClass
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-wrap{
    $self: &;
    &__content {

    }

    &.is-hasbar {
      /* #{ $self }__content  {
        width: 75%;
        flex: 2
      }

      #{$self}__right {
        width: 25%
      } */
    }
  }
</style>
